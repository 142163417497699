<template>
  <dash-page  v-if="$store.state.auth.userType === 'superadmin'"
              title="Заявки на консультацію"
              icon="mdi-account-multiple"
              class="fill-height"
              no-side >

    <v-btn-toggle mandatory v-model="view" background-color="transparent" :color="wsACCENT"   >
      <v-btn small text  class="noCaps font-weight-light" value="table">
        <v-icon small class="mr-1">mdi-table</v-icon>
        {{ $t('Table') }}
      </v-btn>
      <v-btn small text  class="noCaps font-weight-light" value="kanban">
        <v-icon small class="mr-1">mdi-view-dashboard-variant-outline</v-icon>
        {{ $t('Kanban') }}
      </v-btn>
    </v-btn-toggle>


    <v-sheet  v-if="view==='table'" class="pa-6" >
      <v-data-table class="mt-6"
                    dense
                    :items="leadsFiltered"
                    :headers="headers"
                    selectable-key="id" >

        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              <div class="d-flex">
                <h5 class="font-weight-light mr-3">{{  index +1  }}</h5>
                <ws-chip icon="mdi-calendar" :color="wsACCENT">
                  <h5 class="font-weight-medium text-no-wrap" >{{ formatTime(item.date)  }}</h5>
                </ws-chip>
              </div>

            </td>
            <td>
              <h5>{{ item.name }} </h5>
            </td>
            <td>
              <ws-chip icon="mdi-phone" :text="item.phone"/>
            </td>
            <td>
              <ws-chip icon="mdi-email" :text="item.email"/>
            </td>
            <td align="center">
              <div class="d-flex ">
                <ft-select @input="changeStatus($event, item)" v-model="item.status" :items="statusTypes">
                  <ws-chip :icon="getStatusIcon(item.status)" :color="getStatusColor(item.status)">
                    <h5 class="font-weight-medium text-no-wrap" >{{ $t("lead_" + item.status)   }}</h5>
                    <v-icon small>mdi-chevron-down</v-icon>
                  </ws-chip>
                </ft-select>
              </div>

            </td>
            <td>
              <ws-chip v-if="item.date_contacted" icon="mdi-calendar" :color="wsACCENT">
                <h5 class="font-weight-medium text-no-wrap" >{{ formatTime(item.date_contacted)  }}</h5>
              </ws-chip>

              <h5 v-else class="font-weight-medium grey--text"> {{ $t('NotContacted') }}</h5>

            </td>
            <td>
              <v-btn icon :color="wsATTENTION" :to="businessDashLink('news/' + item.uuid)"><v-icon>mdi-pencil-circle</v-icon></v-btn>
            </td>
          </tr>

        </template>

      </v-data-table>
    </v-sheet>

    <div v-if="view === 'kanban'" class="overflow-x-auto d-flex pt-6 px-2 fill-height" >
      <v-sheet  v-for="(items,status) in kanbanData" :key="status"
                class=" mr-6"
                min-width="270" width="270"
                color="transparent"     >

        <div class="d-flex justify-space-between">
          <h4 class="font-weight-medium d-flex align-center">
            <v-icon :color="getStatusColor(status)" class="mr-2" small>{{ getStatusIcon(status) }}</v-icon>
            {{  $t("lead_" + status)  }}</h4>
          <v-chip outlined small> {{ getItemsByStatus(status).length}}  </v-chip>
        </div>

        <v-divider class="mt-3" />
        <draggable
            v-model="kanbanData[status]"
            group="status"
            :forceFallback="true"
            :empty-insert-threshold="120"
            v-bind="opt"
            @start="drag = true"
            @change="changeDroppedStatus($event,status)"
            @end=" drag = false">

          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-sheet  v-for="(item,i) in kanbanData[status]" :key="status + i "
                      class="wsRoundedHalf pa-3 mt-3"
                      min-width="270" width="270">

              <div class="d-flex justify-space-between">
                <div>
                  <ws-chip icon="mdi-calendar" :color="wsACCENT">
                    <h5 class="font-weight-medium text-no-wrap" >{{ formatTime(item.date)  }}</h5>
                  </ws-chip>
                </div>
                <ft-select @input="changeStatus($event, item)" v-model="item.status" :items="statusTypes">
                  <v-btn small icon><v-icon small>mdi-dots-horizontal</v-icon></v-btn>
                </ft-select>

              </div>
              <v-divider />


              <div class="d-flex justify-space-between mt-5">
                <h5>{{  item.name }}</h5>
                <div>
                  <ws-chip icon="mdi-phone" :text="item.phone"/>
                </div>

              </div>

              <h5 v-if="item.comment" class="font-weight-light mt-3 mb-3"> {{ item.comment }}</h5>

            </v-sheet>
          </transition-group>

        </draggable>


      </v-sheet>
    </div>





  </dash-page>

</template>

<script>
import {mapActions} from "vuex";
import draggable from "vuedraggable";

export default {
  name: "DashClientLeads",
  components : {
    draggable
  },
  data() {
    return {
      drag  : false,
      loading : false,
      leads : [],
      view : 'kanban',
      filter : 'new',
      kanbanData : {},
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      kanbanViews : ['new', 'contacted', 'no_contact', 'in_process', 'success', 'refuse'],
    }
  },
  computed : {
    statusTypes() {
      return [
        { text : this.$t("lead_new")        , value : 'new'        },
        { text : this.$t("lead_contacted")  , value : 'contacted'  },
        { text : this.$t("lead_no_contact")  , value : 'no_contact' },
        { text : this.$t("lead_in_process")  , value : 'in_process' },
        { text : this.$t("lead_success")    , value : 'success'    },
        { text : this.$t("lead_refuse")     , value : 'refuse'     },

      ]
    },

    leadsFiltered() {
      let items = JSON.parse(JSON.stringify(this.leads))
      // if ( this.filter && this.filter !== 'all' ) {
      //   items = items.filter(el=> el.status === this.filter )
      // }
      // if ( this.filter && this.filter === 'all' ) {
      //   items = items.filter(el=> !['success','refuse'].includes(el.status) )
      // }
      return items
    },

    headers() {
      return [
        { text : this.$t('Date')    , value : 'date'     , width: 10, align : 'center' , sortable : false },
        { text : this.$t('Name')    , value : 'name'   },
        { text : this.$t('Phone')   , value : 'phone'    },
        { text : this.$t('Email')   , value : 'email'    },
        { text : this.$t('Status')  , value : 'status', width: 10, align : 'center' },
        { text : this.$t('Contact') , value : 'date_contacted'     , width: 10, align : 'center' , sortable : false },
   //     { value : 'action', width : 10, sortable : false }
      ]
    },
  },
  watch : {
    view(value) {
      if ( value === 'kanban') {
        this.initKanban()
      }
    }
  },

  methods : {
    ...mapActions('adminCrmSystem', ['GET_LEADS','EDIT_CLIENT_LEAD']),


    async changeStatus(status,item) {

        if (status) {
          item.status = status
        }
        let result = await this.EDIT_CLIENT_LEAD(item)
        if ( !result ) { return this.notify(this.$t('NetworkError'))}
        let index = this.leads.findIndex(el=>el.id === result.id)

        if ( index !== -1) {
          this.leads[index] = result
          this.leads = JSON.parse(JSON.stringify(this.leads))
        }
        this.initKanban()

        this.displayDialog = false
        this.notify(this.$t('ChangesSaved'))

    },

    initKanban() {
      this.kanbanData = {};
      this.kanbanViews.forEach((status)=>{
        this.kanbanData[status] = this.getItemsByStatus(status)
      })
    },
    getItemsByStatus(status) {
      if (this.leads.length === 0 ) { return [] }
      return this.leads.filter(el=>el.status === status)
    },
    changeDroppedStatus($event,status) {
      if ( $event.added ) {
        this.changeStatus(status,$event.added.element)
      }
    },
    //technical
    getStatusColor(status) {
      switch(status) {
        case 'contacted' : return 'green lighten-1 '
        case 'success' : return 'green lighten-1'
        case 'in_process' : return 'green lighten-1 '
        case 'no_contact'  : return 'red lighten-1'
        case 'refuse'  : return 'red lighten-1'
        default : return this.wsACCENT
      }
    },
    //technical
    getStatusIcon(status) {
      switch(status) {
        case 'new' : return 'mdi-alert-decagram'
        case 'contacted' : return 'mdi-phone'
        case 'no_contact' : return 'mdi-phone-cancel'
        case 'refuse' : return 'mdi-close'
        case 'success' : return 'mdi-check'
        case 'in_process' : return 'mdi-cogs'
        default : return 'mdi-cog'
      }
    },

  },
  mounted() {
    this.loading = true
    this.GET_LEADS().then((out)=>{
      if ( out.result ) {
        this.leads = out.data
        this.leads.reverse()
      }
      this.initKanban()
      this.loading = false
    }).catch(()=>this.loading = false)

  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>